import * as React from "react";
import { FC } from "react";
import { SitemapEntrypoint } from "../../entrypoints/sitemap";
import { LANG } from "../../common/types/localization";
import { DefaultLayout } from "../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.plPL} entrypoint={"sitemap"} page={"mapa_strony"}>
    <SitemapEntrypoint lang={LANG.plPL} />
  </DefaultLayout>
);
