import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Migration } from "../common/components/migration/migration";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import { SitemapNavigation } from "../components/navigation/sitemap-navigation";

export const SitemapEntrypoint: FC<ILocalizedPage> = ({ lang }) => {
  const title = useLocalized("sitemap_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <SitemapNavigation lang={lang} />
    </>
  );
};
