import * as React from "react";
import { FC } from "react";
import { LANG } from "../../common/types/localization";
import { sitemapItems } from "./data";
import { Navigation } from "./navigation";
import theme from "./sitemap-navigation-theme.module.css";

interface ISitemapNavigation {
  lang: LANG;
}

export const SitemapNavigation: FC<ISitemapNavigation> = ({ lang }) => (
  <Navigation lang={lang} theme={theme} items={sitemapItems} />
);
